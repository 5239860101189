@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide_scrollbar::-webkit-scrollbar {
  display: none;

}

.hide_scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* For WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  height: 6px;
  /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f4f4f4;
  /* color of the track */
}

::-webkit-scrollbar-thumb {
  background: #8888885c;
    width: 6px;
    border-radius: 8px;
  /* color of the thumb */
}

.four-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}