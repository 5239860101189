body{
  margin: 0%;
  padding: 0%;
  background-color: #e6e6e6;
}
img{
  width: 100%;
}

a{
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}